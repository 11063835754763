import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { Image, Text } from "../../components/Core"
import { ButtonGroupMap, ButtonGroup, Button } from "../../components/Button"

// Only is set up for 2 or 5 docs
function MeetOurDoctors(props) {
  if (!props.post.meetOurDoctors.hasThisSection) return <></>

  const data = props.post.meetOurDoctors
  const docLength = data.images.length
  const esDocClass = props.language === "es" ? "doc-info-es" : ""

  const mainClasses = classNames("body-section", "doc-info", {
    "color-back": props.colorBack
  })

  const esMainClasses = classNames("doc-info-es", "body-section", "doc-info", {
    "color-back": props.colorBack
  })

  // split doc images into 2 arrays
  let docChunks = [[], []]

  // if 4 or 5 doctors, top row gets 2. else show 3
  let arrayLimit = 3
  if (docLength === 5 || docLength === 4) {
    arrayLimit = 2
  }

  data.images.forEach((dr, i) => {
    if (docChunks[0].length >= arrayLimit) {
      docChunks[1].push(dr)
    } else {
      docChunks[0].push(dr)
    }
  })

  return (
    <section
      className={` ${props.language === "es" ? esMainClasses : mainClasses}`}>
      <div className="columns">
        <div className="column is-4"></div>
        <div className="column has-text-centered-tablet">
          <Text useStringReplace text={data.text} />
        </div>
        <div className="column is-4"></div>
      </div>
      {data.useButtons && (
        <ButtonGroup isCentered>
          {data.images.map((dr, i) => (
            <div key={i}>
              <h5>{dr.doctor.caption}</h5>
              <Button
                buttonText={
                  props.language === "es" ? "Aprenda Mas" : "Learn More"
                }
                href={dr.doctor.url}
                appearance="minimal"
              />
            </div>
          ))}
        </ButtonGroup>
      )}

      {!data.useButtons && (
        <div className={`our-docs is-centered has-${docLength}-docs`}>
          <DoctorImages doctors={docChunks} language={props.language} />
        </div>
      )}
    </section>
  )
}

function DoctorImages({ doctors, language }) {
  const styles = {
    captions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between"
    },
    learnMore: {
      fontWeight: 600,
      fontSize: "14px",
      textDecoration: "underline",
      marginBottom: 0,
      padding: "0 !important"
    },
    docName: {
      fontSize: "14px",
      marginBottom: 0
    }
  }

  const columnClasses = classNames("column", "doc-column", {
    "is-8": doctors[0].length === 1,
    "is-7": doctors[0].length === 2,
    "is-6": doctors[0].length >= 3
  })

  return (
    <>
      <div className="columns is-centered">
        {doctors[0] &&
          doctors[0].map((image, i) => (
            <React.Fragment key={i}>
              <div className={columnClasses}>
                <Link to={image.doctor.url}>
                  <Image
                    publicId={image.doctor.imageId}
                    className="meet-our-doctor rounded shadow"
                  />
                </Link>

                <div className="docs-2-3-caption" style={styles.captions}>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor" style={styles.docName}>
                      {image.doctor.caption}
                    </h5>
                  </Link>

                  <Button
                    buttonText={
                      language === "es" ? "Aprenda Mas" : "Learn More"
                    }
                    href={image.doctor.url}
                    appearance="contained"
                  />
                </div>
              </div>
              {i !== doctors[0].length - 1 && (
                <div className="column is-1"></div>
              )}
            </React.Fragment>
          ))}
      </div>

      {doctors[1].length >= 1 && (
        <>
          <div className="mt-40--desktop"></div>

          <div className="columns is-centered">
            {doctors[1] &&
              doctors[1].map((image, i) => (
                <React.Fragment key={i}>
                  <div className={columnClasses}>
                    <Link to={image.doctor.url}>
                      <Image
                        publicId={image.doctor.imageId}
                        className="meet-our-doctor rounded shadow"
                      />
                    </Link>

                    <div className="docs-2-3-caption" style={styles.captions}>
                      <Link to={image.doctor.url}>
                        <h5
                          className="image-caption doctor"
                          style={styles.docName}>
                          {image.doctor.caption}
                        </h5>
                      </Link>

                      <Button
                        buttonText={
                          language === "es" ? "Aprenda Mas" : "Learn More"
                        }
                        href={image.doctor.url}
                        appearance="contained"
                      />
                    </div>
                  </div>
                  {i !== doctors[1].length - 1 && (
                    <div className="column is-1"></div>
                  )}
                </React.Fragment>
              ))}
          </div>
        </>
      )}
    </>
  )
}

export default MeetOurDoctors
