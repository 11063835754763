import React from "react"

import { Image, Text } from "../../components/Core"
import { Section, Columns } from "../../components/Containers"
import TheaterVideo from "@components/Theater/TheaterVideo"

function TopSection({ youtube, desktopImage, mobileImage, language }) {
  return (
    <Section zeroTopMobile>
      <Columns sideColumnsSize={4}>
        <div className="column">
          {youtube && desktopImage && mobileImage ? (
            <TheaterVideo
              buttonClass="contained"
              videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
              controls
              playing
              overVideo
              language={language}>
              <Image
                wrapperClassName="show-desktop-only"
                className="rounded"
                publicId={desktopImage}
                useAR
              />
              <Image
                wrapperClassName="show-desktop-only-inverse"
                publicId={mobileImage}
                useAR
              />
            </TheaterVideo>
          ) : (
            <>
              <Image
                wrapperClassName="show-desktop-only"
                className="rounded"
                publicId={desktopImage}
                useAR
              />
              <Image
                wrapperClassName="show-desktop-only-inverse"
                publicId={mobileImage}
                useAR
              />
            </>
          )}
        </div>
      </Columns>
    </Section>
  )
}

export default TopSection
